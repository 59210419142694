<template>
  <div class="multiselect-area">
    <label
      v-if="labelDesc.length"
      class="multiselect-area__label-desc" for="id"
      :class="{ 'multiselect-area__label-desc--change-checkbox': showChangeCheckbox }"
    >
      {{ labelDesc }}
      <span v-if="required" class="required">*</span>
    </label>
    <div :class="{
      'multiselect-area__group--change-checkbox': showChangeCheckbox,
      'multiselect-area__group--counter': showCounter
    }">
      <Checkbox
        v-if="showChangeCheckbox"
        :id="`change-${id}`"
        :value="changeCheckbox"
        @input="$emit('change-checked', $event)"
        class="multiselect-area__change-checkbox"
      />
      <MultiSelect
        ref="multiselectReference"
        :id="id"
        :value="value"
        @input="onInput"
        :options="options"
        :label="label"
        :track-by="trackBy"
        :placeholder="customPlaceholder"
        :multiple="multiple"
        :close-on-select="closeOnSelect"
        :clear-on-select="clearOnSelect"
        :hide-selected="hideSelected"
        selected-label=""
        deselect-label="X"
        :preserve-search="preserveSearch"
        :preselect-first="preselectFirst"
        :select-label="selectLabel"
        :loading="loading"
        :internal-search="internalSearch"
        :options-limit="optionsLimit"
        :limit="limit"
        :max-height="maxHeight"
        :show-no-results="showNoResults"
        :open-direction="openDirection"
        :custom-label="customLabel"
        @search-change="searchChangeDebounce"
        @remove="$emit('remove', $event)"
        autocomplete="off"
        :data-test="dataAttr ? dataAttr : id"
        :disabled="disabled"
        :taggable="taggable"
        :tag-placeholder="tagPlaceholder"
        :searchable="searchable"
        @tag="$emit('tag', $event)"
        @open="onOpen"
        :class="{
          'multiselect-area__select--error': error,
          'multiselect-area__select--disabled-open': disableOpening
        }"
      >
        <span slot="noResult">{{ $t('multiselect_no_result') }}</span>
        <template slot="noOptions">{{ noOptionsText }}</template>
      </MultiSelect>
      <div class="input-group-append" v-if="!trackBy && showCounter">
        <span class="input-group-text text-center">
          <template v-if="value">{{ value.length }}</template>
          <template v-if="!value">0</template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelect from 'vue-multiselect'
import Checkbox from '@/components/form/Checkbox'
import { debounce } from 'lodash'

export default {
  name: 'Multiselect',
  props: {
    id: {
      type: String,
      required: true
    },
    closeOnSelect: {
      type: Boolean,
      default: false
    },
    clearOnSelect: {
      type: Boolean,
      default: true
    },
    dataAttr: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String
    },
    error: {
      type: Boolean,
      default: false
    },
    disableFormGroup: {
      type: Boolean,
      default: true
    },
    trackBy: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    showCounter: {
      type: Boolean,
      default: false
    },
    preselectFirst: {
      type: Boolean,
      default: false
    },
    preserveSearch: {
      type: Boolean,
      default: true
    },
    selectLabel: {
      type: String,
      default: ''
    },
    status: {
      type: String
    },
    options: {
      type: Array
    },
    value: {},
    loading: {
      type: Boolean,
      default: false
    },
    internalSearch: {
      type: Boolean,
      default: true
    },
    optionsLimit: {
      type: Number,
      default: 300
    },
    limit: {
      type: Number,
      default: 10
    },
    maxHeight: {
      type: Number,
      default: 600
    },
    showNoResults: {
      type: Boolean,
      default: true
    },
    openDirection: {
      type: String,
      default: ''
    },
    customLabel: {
      type: Function
    },
    labelDesc: {
      type: String,
      default: ''
    },
    showChangeCheckbox: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    noOptionsText: {
      type: String,
      default: ''
    },
    hideSelected: {
      type: Boolean,
      default: true
    },
    taggable: {
      type: Boolean,
      default: false
    },
    tagPlaceholder: {
      type: String,
      default: '(enter)'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: true
    },
    disableOpening: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      changeCheckbox: false
    }
  },
  components: {
    MultiSelect,
    Checkbox
  },
  computed: {
    customPlaceholder () {
      if (this.placeholder === '') {
        return this.$t('pick_some')
      }
      return this.placeholder
    }
  },
  methods: {
    openOptions () {
      this.$refs.multiselectReference.$el.focus()
    },
    searchChangeDebounce: debounce(function (query) {
      this.searchChange(query)
    }, 500),
    searchChange (query) {
      this.$emit('search-change', query)
    },
    refresh () {
      this.$refs.multiselectReference.$emit('input', this.value)
    },
    setSearchText (text) {
      this.$refs.multiselectReference.search = text
    },
    onInput (event) {
      if (this.showChangeCheckbox) {
        this.changeCheckbox = true
        this.$emit('change-checked', true)
      } else {
        this.$emit('change-checked')
      }
      this.$emit('input', event)
      this.$emit('blur', event)
    },
    onOpen () {
      if (this.disableOpening) {
        this.$refs.multiselectReference.deactivate()
      } else {
        this.$emit('open')
      }
    }
  },
  mounted () {
    if (this.searchable && this.$refs.multiselectReference?.$refs?.search) {
      this.$refs.multiselectReference.$refs.search.setAttribute('autocomplete', 'off')
    }
  }
}
</script>

// Multiselect plugin
<style src="../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import 'src/assets/scss/components/multiselect';
</style>
// Custom css
<style scoped lang="scss">
  .multiselect-area {
    &__change-checkbox {
      padding-top: rem(8px);
    }
    &__label-desc {
      font-family: "Roboto", sans-serif;
      font-size: rem(14px);
      font-weight: 400;
      color: #8A96AC;
      &--change-checkbox {
        padding-left: rem(25px);
      }
    }
    &__group {
      &--change-checkbox {
        display: grid;
        grid-template-columns: max-content auto;
      }
      &--counter {
        display: grid;
        grid-template-columns: auto max-content;
      }
    }
  }
</style>
<style lang="scss">
.multiselect-area {
  &__select {
    &--error {
      .multiselect__tags {
        border: 1px solid #FF3455;
      }
    }
    &--disabled-open {
      .multiselect__select {
        display: none;
      }
    }
  }
}
</style>
